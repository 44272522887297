
/* Footer */
.wFormContainer {

  .wFormFooter {

    /* Assistance link */
    .supportInfo {
      text-align: center;
      a {
        color: $color-link-color;
      }
      a:hover {
        color: $color-link-color;
      }
    }
  }
}
