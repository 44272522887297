
/** Calendar - popup is inserted outside of the .wFormContainer **/
html {

  /* Calendar container */
  .kalendae {
    background: $color-overlay-background-color;
    font-size: $length-scale-size;
    font-family: $font-font;
    border-radius: 0;
    padding: 1rem;

    /** Container for the 1-month view **/
    .k-calendar {

      /* ensures proper alignment of the 7 days */
      width: 14em; /* divisible by 7 */
      .k-header span, .k-days span {
        /* 1/7th of the width, minus margin. */
        width: calc(2em - 2px) !important;
        /* margin */
        margin-left: 1px !important;
        margin-right: 1px !important;
        /* ensure any border or padding is accounted for. */
        box-sizing: border-box !important;
      }

      .k-title, .k-header, .k-days {
        width: 100%;
        height: auto;
        margin: 0;
      }
      .k-header span,
      .k-days span {
        height: inherit;
        line-height: inherit;
        text-align: right;
        padding: 2px .25em 2px 2px;

      }
      /** Kalendae.Input's popup close button **/
      .k-btn-close {

      }
      .k-btn-close:hover {
        color: $color-accent-color;
        border-color: $color-accent-color;
      }
      /** Month Title Row **/
      .k-caption {
        font-size: $length-scale-size;
        color: $color-accent-color;
      }
      /** Month and Year Buttons **/
      .k-btn-previous-month,
      .k-btn-next-month,
      .k-btn-previous-year,
      .k-btn-next-year {
        color: $color-accent-color;
        line-height: normal;
        height: auto;
        font-size: 1.4em;
      }

      .k-btn-previous-month:hover,
      .k-btn-next-month:hover,
      .k-btn-previous-year:hover,
      .k-btn-next-year:hover {
        color: $color-accent-color;
      }

      .k-header span {
        color: $color-accent-color;
      }

      /** Days in the current month **/
      .k-days span.k-in-month.k-active {
        color: $color-text-color;
        border-color: rgba(0,0,0,.1);
        background-color: rgba(0,0,0, .08);
      }
      /* Days outside of the current month */
      .k-days span.k-out-of-month {
        color: rgba(0,0,0,.25);
      }

      /** Selected day, when inside the selectable area **/
      .k-days span.k-selected.k-active {
        background: $color-accent-color;
        color: $color-inverse-accent-color;
      }
      /** Selectable day, hovered **/
      .k-days span.k-active:hover {
        border-color: $color-accent-color;
      }
    }
  }
}