
@mixin button {
  background-color: $color-accent-color;
  color: $color-inverse-accent-color;
  margin-top: 1.6em;
  padding: 0.75em 2em;
  -webkit-appearance: none;
  border-radius: 40px;
  border: none;
}

.wFormContainer {

  /* Container for the submit button */
  .actions {
    margin-top:0; /* required to preserve alignment of previous/next and submit buttons. */

    /* Submit button */
    .primaryAction {
      @include button;
    }
    /* Hover state */
    .primaryAction:hover {
      background-color: $color-inverse-accent-color;
      color: $color-accent-color;
    }
    /* Disabled state (submission in progress) */
    .primaryAction:disabled {
      background-color: rgba(0,0,0,0.2);
      color: rgba(255,255,255,0.7);
    }
  }

  /* Container for the page next/previous buttons */
  .wfPagingButtons {
    margin-top: 0;

    /* Page next/previous buttons */
    .wfPageNextButton, .wfPagePreviousButton {
      @include button;
    }

    /* Hover state */
    .wfPageNextButton:hover, .wfPagePreviousButton:hover {
      background-color: $color-inverse-accent-color;
      color: $color-accent-color;
    }
  }
}