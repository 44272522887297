$form-padding: 1rem 2rem;
$form-background-color: #E4E4E4;
$form-border: none;

/* Main container. Must wrap *all* theme CSS */
.wFormContainer {

  /* Container for title and form */
  .wForm {
    background-color: $form-background-color;
    color: $color-text-color;
    border: $form-border;

      /* Form */
      form {
        padding: $form-padding;
      
        /* 2-Column Form */
        .flex-container {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
        
            /* */
            .section.group div:first-child {
                margin-top: 0;
            }
        }
      }
    }
}


/* Optional - Apply for FormAssembly hosted forms only. */
.wFormWebPage {
  background-color: $color-page-background;
}


@media only screen and (max-width: 600px) {
  .wFormWebPage {
    margin: 0 !important;
  }
  .wFormContainer .wForm form .flex-container {
    display: block;
  }
}

