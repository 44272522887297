$input-padding: .5em;
$input-background-color: rgba(255, 255, 255, 0);
$input-border: 1px solid rgb(0, 0, 0);
$input-border-radius: 0;
$input-height: 2.5em;
$input-default-width: 25em;

/* Main container. Must wrap *all* theme CSS */
.wFormContainer {

  .wForm {

    .oneField {
      padding:0;
    }

    /* Email Wrapper */
    #emailWrapper {
      min-width: 87%;
    }

     /* container for the input(s) and decorators */
    .inputWrapper {

      /* inputs */
      input[type='text'],
      input[type='password'],
      select,
      textarea {
        color: $color-text-color;
        border: $input-border;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-radius: $input-border-radius;
        box-sizing: border-box;
        font-size: 1em;
        width: $input-default-width;
        background-color: $input-background-color;
      }

      input[type='text'],
      input[type='password'],
      input[type='file'],
      select:not([multiple]) {
        height: $input-height;
        padding: 0 $input-padding;
      }

      .email {
        min-width: 100%;
      }

      select[multiple] {
        width: auto;
      }

      input[type='file'] {
        border: none;
      }

      textarea {
        height: 5em;
        padding: $input-padding;
      }
      select option {
        padding: 0.25em $input-padding;
      }
      input.readonly, textarea.readonly {
        border: $input-border !important;
        background-color: rgba(0,0,0,.35) !important;
      }
      /* Input with focus */
      textarea:focus,
      input[type='text']:focus,
      input[type='password']:focus,
      select:focus {
        outline-style: none;
        border-color: $color-accent-color;
      }
      /* Placeholder Text */
      ::placeholder {
        color: rgba(0,0,0,.25) !important;
      }

      /* autosuggest background color */
      .twitter-typeahead {
        background-color: $input-background-color;
      }
    }
  }
}