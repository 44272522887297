@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700");
$font-font: 'Roboto';
$length-scale-size: 95%;

.wFormContainer {
  font-family: $font-font, sans-serif;
  font-size: $length-scale-size;

  a {
    text-decoration: none;
    color: $color-link-color !important;
  }
  a:hover {
    text-decoration: underline;
    color: $color-link-color !important;
  }
  .htmlSection {
    margin-top: 1em;
  }
}

